// obtain plugin

require('vanilla-cookieconsent')
var cc = initCookieConsent();
// run plugin with your configuration
cc.run({
    current_lang: document.documentElement.getAttribute('lang') == 'fr' ? 'fra' : 'eng',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
     auto_language: document,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
     cookie_name: 'CookieConsent',               // default: 'cc_cookie'
     cookie_expiration: 182,                 // default: 182 (days)
     cookie_necessary_only_expiration: 182,   // default: disabled
     cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'fra': {
            consent_modal: {
                title: 'Gestionnaire de cookies',
                description: 'Nous utilisons des cookies sur ce site pour améliorer votre expérience d\'utilisateur. Certaines fonctionnalités essentielles de ce site ne fonctionneront pas sans cookies. Veuillez vérifier vos paramètres de cookies ci-dessous et cocher la case des cookies que vous acceptez.<button type="button" data-cc="c-settings" class="cc-link">Configurer</button>',
                primary_btn: {
                    text: 'Je suis d\'accord',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Je ne suis pas d\'accord',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Configurer',
                save_settings_btn: 'Sauvegarder vos préférences',
                accept_all_btn: 'Je suis d\'accord',
                reject_all_btn: 'Je ne suis pas d\'accord',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    {col1: 'Nom'},
                    {col2: 'Domaine'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Usage des cookies',
                        description: 'Certains cookies sont nécessaires à des fins techniques, ils sont donc dispensés de consentement. D\'autres, non obligatoires, peuvent être utilisés pour la personnalisation des annonces et du contenu, la mesure des annonces et du contenu, la connaissance de l\'audience et le développement de produits, les données de géolocalisation précises et l\'identification par le balayage de l\'appareil, le stockage et/ou l\'accès aux informations sur un appareil. '
                    }, {
                        title: 'Strictement nécessaire',
                        description: 'Nécessaire au bon fonctionnement du site web et ne peut être désactivé dans notre système. Ces cookies sont essentiels pour les fonctions de base du site web et le site web ne fonctionnera pas comme prévu sans eux. Ces cookies ne stockent aucune donnée personnelle identifiable.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: 'CookieConsent',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '6 mois',
                                col4: 'Stocke l\'autorisation d\'utilisation de cookies pour le domaine actuel par l\'utilisateur',
                                is_regex: true
                            },
                            {
                                col1: 'XSRF-TOKEN',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '1 jour',
                                col4: 'Laravel génère automatiquement un "token" CSRF pour chaque session utilisateur active gérée par l\'application. Ce jeton est utilisé pour vérifier que l\'utilisateur authentifié est celui qui fait effectivement les requêtes à l\'application.',
                                is_regex: true
                            },
                            {
                                col1: 'laravel_session',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '1 jour',
                                col4: 'PHP utilise un cookie pour identifier les sessions des utilisateurs. Sans ce cookie, le site Web ne fonctionne pas.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Publicités personnalisées et mesure de performance des annonces',
                        description: 'Nous permet de compter les visites et les sources de trafic afin de pouvoir mesurer et améliorer les performances de notre site. Ces cookies permettent de fournir des informations sur le nombre de visiteurs, le taux de rebond, la source de trafic, etc. Sans ces cookies, nous ne serons pas en mesure de recueillir des données précises afin d\'identifier les améliorations à apporter au site web pour améliorer votre expérience.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '13 mois',
                                col4: 'Enregistre un identifiant unique utilisé pour générer des données statistiques sur la façon dont le visiteur utilise le site.',
                                is_regex: true
                            },
                            {
                                col1: '_ga_container-id',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '13 mois',
                                col4: 'Utilisé par Google Analytics our recueillir des données sur le nombre de fois qu\'un utilisateur a visité le site web ainsi que les dates de la première et de la plus récente visite.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Marketing',
                        description: 'Les cookies marketing sont utilisés pour effectuer le suivi des visiteurs au travers des sites web. Le but est d\'afficher des publicités qui sont pertinentes et intéressantes pour l\'utilisateur individuel et donc plus précieuses pour les éditeurs et annonceurs tiers.',
                        toggle: {
                            value: 'targeting',
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'NID',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '6 mois',
                                col4: 'Ce cookie permet de personnaliser les annonces sur les sites Google, tels que la recherche Google. Il permet, par exemple, à enregistrer vos recherches les plus récentes, vos interactions précédentes avec les résultats de recherche ou les annonces d\'un annonceur, ainsi que vos visites sur le site Web d\'un annonceur. Cela permet d\'afficher des annonces personnalisées sur Google.',
                                is_regex: true
                            }
                        ]
                    }
                ]
            }
        },
        'eng': {
            consent_modal: {
                title: 'Cookies seetings',
                description: 'Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.<button type="button" data-cc="c-settings" class="cc-link">Settings</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie settings',
                save_settings_btn: 'Save current selection',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'Some cookies are necessary for technical purposes, so they are exempt from consent. Others, not required, may be used for ad and content personalization, ad and content measurement, audience insights and product development, precise geolocation data, and user identification. device scanning, storing and accessing information on a device. '
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'Necessary for the proper functioning of the website and cannot be deactivated in our system. These cookies are essential for basic website functions and the website will not function as intended without them. These cookies do not store any personally identifiable data..',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: 'CookieConsent',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '6 months',
                                col4: 'Stores the user\'s permission to use cookies for the current domain',
                                is_regex: true
                            },
                            {
                                col1: 'XSRF-TOKEN',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '1 day',
                                col4: 'Laravel automatically generates a CSRF "token" for each active user session handled by the application. This token is used to verify that the authenticated user is the one actually making the requests to the application.',
                                is_regex: true
                            },
                            {
                                col1: 'laravel_session',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '1 day',
                                col4: 'PHP uses a cookie to identify user sessions. Without this cookie, the website does not work.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Personalized ads and ad performance measurement',
                        description: 'Allows us to count visits and traffic sources so that we can measure and improve the performance of our site. These cookies are used to provide information on the number of visitors, the bounce rate, the source of traffic, etc. Without these cookies, we will not be able to collect accurate data to identify improvements to the website to improve your experience.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '13 months',
                                col4: 'Registers a unique identifier used to generate statistical data on how the visitor uses the site.',
                                is_regex: true
                            },
                            {
                                col1: '_ga_container-id',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '13 months',
                                col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website and the dates of the first and most recent visit.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Marketing',
                        description: 'Marketing cookies are used to track visitors across websites. The aim is to display advertisements that are relevant and interesting to the individual user and therefore more valuable to third-party publishers and advertisers.',
                        toggle: {
                            value: 'targeting',
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'NID',       // match all cookies starting with "_ga"
                                col2: window.location.hostname,
                                col3: '6 months',
                                col4: 'This cookie is used to personalize ads on Google sites, such as Google Search. It allows, for example, to record your most recent searches, your previous interactions with search results or advertisements of an advertiser, as well as your visits to an advertiser\'s website. This allows personalized ads to be displayed on Google.',
                                is_regex: true
                            }
                        ]
                    }
                ]
            }
        }
    },
    gui_options: {
        consent_modal: {
            layout: 'bar',               // bar/cloud/bar
            position: 'bottom right',     // bottom/middle/top + left/right/center
            transition: 'zoom',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    }
});
